body {
    margin: 0; /* Reset default margin */
    padding: 0; /* Reset default padding */
    position: relative;
}

.documentation-container {
    width: 100%; /* Set width to 100% of the viewport */
    max-width: none; /* Remove max-width constraint */
    margin: 0; /* Remove default margin */
}

.help-container {
    max-width: 50vw; /* Adjusted to match the width of .content */
    border-radius: 1vw; /* Rounded corners only at the bottom */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.415); /* Drop shadow for depth */
    padding: 20px;
    margin: 0 auto; /* Center horizontally */
}

.faq {
    background-color: #f9f9f9; /* Match background color of content */
    border-radius: 0vw; /* Match rounded corners */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1); /* Match drop shadow */
    padding: 2vw; /* Match padding */
    margin-bottom: 2vh; /* Add margin for spacing */
}

.faq h3 {
    color: #333; /* Match text color */
    margin-top: 0; /* Remove default margin for heading */
}

.faq p {
    color: #666; /* Match text color */
    line-height: 1.6;
}

.user-guide {
    background-color: #f9f9f9; /* Match background color of content */
    border-radius: 10px; /* Match rounded corners */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1); /* Match drop shadow */
    padding: 20px; /* Match padding */
}

.user-guide h3 {
    color: #333; /* Match text color */
    margin-top: 0; /* Remove default margin for heading */
}

.user-guide a {
    color: #007bff; /* Match link color */
    text-decoration: none;
}

.user-guide a:hover {
    text-decoration: underline; /* Add underline on hover */
}



.background-gif {
    width: 100%; /* Set width to 100% of the viewport */
    height: 325px; /* Adjust height as needed */
    min-height: 300px;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5)), url('../assets/documentationBackground.jpeg'); /* Specify the correct path to your GIF */
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    padding-bottom: 20px;
}


.header {
    position: relative;
    overflow: hidden;
}

.title {
    text-align: center;
    position: relative;
    color: black;
    font-size: 4rem;
    font-family: 'Roboto'; /* Example grand font */
    margin-top: 350px;
    z-index: 1; /* Adjust as needed */
    padding: 20px;
}  

.content {
    max-width: 50vw;
    background-color: #f9f9f9;
    border-radius: 1vw; /* Rounded corners only at the bottom */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.415); /* Drop shadow for depth */
    padding: 20px;
    margin: 0 auto; /* Center horizontally */
    margin-top: 3vh;
}

.content h2 {
    color: #333;
    margin-top: 0; /* Remove default margin for heading */
}

.content p {
    color: #666;
    line-height: 1.6;
}

.video-container {
    position: relative;
    width: 50%; /* Set the container width to half of its parent's width */
    padding-top: 25%; /* 2:1 aspect ratio (1 / 2 * 100) */
  }
  
  .video-frame {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
    width: 100%; /* Set the iframe width to fill its container */
    height: 100%; /* Set the iframe height to fill its container */
  }
  